/**
Les règles de ce fichier ne sont pas en classe tailwind car ils
stylisent du code provenant de la base de données.
 */

.faq-header > ul {
    padding-left: 4rem;
}

.faq-header > h2 > a {
    color: #1E2E5F; /* SecondaryDark */
    /*text-decoration: underline;*/
}

.faq-header > h2 {
    margin-bottom: 2rem;
    font-size: 1.6rem;
}

.faq-header.faq-header--preter > h2 {
    color: #3757A5;
}

.faq-header.faq-header--emprunter > h2 {
    color: #2F4F4F
}

.faq-header > h1 {
    margin-bottom: 2rem;
}

.faq-header > p {
    margin-bottom: 2rem;
}

.faq-article {
    flex: 0 0 calc(50% - 2rem);
}

.faq-article__content > ul {
    padding-left: 4rem;
    list-style-type: square;
}

.faq-article__content > strong {
    color: #3757A5;
}