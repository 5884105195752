@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .base-h2-secondary {
        @apply text-4xl font-bold text-white bg-secondary py-3 rounded-lg text-center;
    }
    .base-h2-primary {
        @apply text-4xl font-bold text-white bg-primary py-3 rounded-lg text-center;
    }
    .base-h2-white {
        @apply text-4xl font-bold text-secondary bg-white py-3 rounded-lg text-center;
    }
    .base-h3 {
        @apply my-3 text-2xl font-semibold;
    }

    .btn {
        @apply px-4 py-3 rounded-lg transition;
    }
    .btn.btn--secondary {
        @apply bg-secondary hover:bg-secondaryLight text-white;
    }
    .btn.btn--secondaryLight {
        @apply bg-secondaryLight hover:bg-secondary text-white;
    }
    .btn.btn--white {
        @apply border-secondary border-2 rounded-lg text-secondary;
    }
    .btn.btn--primary {
        @apply bg-primary hover:bg-primaryLight text-white;
    }

    .card {
        @apply py-4 text-center border-2 border-gray-300 rounded-lg;
    }
    .card-h3 {
        @apply font-bold text-lg text-black;
    }

}

/*Breakpoint tailwind sm*/
@media screen and (max-width: 640px) {

}

/*Breakpoint tailwind md*/
@media screen and (min-width: 641px) and (max-width: 768px) {

}

/*Breakpoint tailwind lg*/
@media screen and (min-width: 769px) and (max-width: 1024px) {

}

/* Breakpoint tailwind xl */
@media screen and (min-width: 1025px) {

}