.container-generic h1 {
    font-size: 34px;
    color: #4161a9;
    padding-top: 0;
    padding-top: 10px;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 30px;
}

.container-generic h2 {
    font-size: 26px;
    color: #4161a9;
    border-bottom: 1px solid #ddd;
    font-weight: normal;
    /*text-align:center;*/
    padding: 0px 0px 5px 0px;
    margin: 20px 0px 15px 0px;
}

.container-generic h3 {
    margin: 40px 0 16px 0;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: #3858a3;
}

.container-generic p {
    font-size: 16px;
    color: #4a4a4a;
    margin-bottom: 20px;
}
.container-generic p .accent-color {
    color: #3858a3;
    font-weight: bold;
}

.container-generic ul {
    padding-left: 40px;
    list-style-type: square;
}

.container-generic ol {
    padding-left: 40px;
    list-style-type: decimal;
}


#body_tutos_liste .header_tutos {
    padding-top: 50px;
    padding-bottom: 100px;
    text-align: center;
    background-color: #4161a9;
}

#body_tutos_liste .header_tutos h1 {
    color: #fff;
    font-weight: bold;
    margin-bottom: 10px;
}

#body_tutos_liste .header_tutos p {
    color: #fff;
    margin-bottom: 10px;
}

#body_tutos_liste .videotuto p:first-child {
    text-align: right;
}

#body_tutos_liste .videotuto a {
    color: #fff;
}

#body_tutos_liste .container-generic {
    margin-top: -120px;
}

#body_tutos_liste .un_tuto {
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.14);
    transition-duration: 0.3s;
}

#body_tutos_liste .un_tuto:hover {
    box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

#body_tutos_liste .un_tuto img {
    max-height: 240px;
}

#body_tutos_liste .un_tuto .contenu_tuto {
    padding: 10px 15px;
    margin-bottom: 40px;
}
@media (min-width: 766px) {
    #body_tutos_liste .un_tuto .contenu_tuto {
        min-height: 145px;
    }
}
#body_tutos_liste .un_tuto .contenu_tuto h3 {
    font-family: "myriadbold";
    border: 0;
    margin-top: 5px;
    margin-left: 0;
    color: #4161a9;
    font-size: 20px;
    line-height: 25px;
    min-height: 55px;
}

#body_tutos_liste .un_tuto .contenu_tuto p {
    color: #777;
}

#body_tutos_liste .besoin-aide {
    text-align: center;
}

#body_tutos_liste .besoin-aide h2 {
    border: 0;
    color: #666;
    font-weight: bold;
}

#body_tutos_liste .besoin-aide a {
    margin: 0px 15px;
}

#body_tutos_liste .glyphicon-play {
    font-size: 13px;
}

#body_tutos_liste .videotuto {
    margin-top: -200px;
}

#body_tutos_liste .videotuto iframe {
    margin-bottom: -7px;
}

.titre_video_tuto {
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.14);
    position: relative;
    background-color: #fff;
}

.titre_video_tuto h1 {
    margin: 0;
    padding: 0;
}

.titre_video_tuto p {
    margin-top: 5px;
}

.texte_tuto {
    width: 65%;
    padding: 30px;
}

.texte_tuto h1 {
    font-weight: bold;
}

.bt_tuto_suivant {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    white-space: nowrap;
    width: 150px;
    height: 100%;
    padding-right: 100px;
    padding-left: 30px;
    box-sizing: border-box;
    background-color: #4161a9;
    /*background-image: url("/websites/pretup-1/images/Fleche-next-tuto.png");*/
    /*background-repeat: no-repeat;*/
    /*background-position: center right 45px;*/
}

.DivWhichNeedToBeVerticallyAligned {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
}

.DivHelper {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
}

.bt_tuto_suivant h3, .bt_tuto_suivant p, .bt_tuto_suivant a {
    color: #fff;
    border: 0;
    padding: 0;
    margin: 0;
}

.bt_tuto_suivant h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}